// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';
@import '~tiny-slider/src/tiny-slider';

a {
  text-decoration: none;
}

body {
  overflow-y: scroll;
  background: #fff;

  .hide-mobile {
    @media (max-width: 768px) {
      display: none !important;
    }
  }

  .unhide-mobile {
    display: none !important;

    @media (max-width: 768px) {
      display: flex !important;
    }
  }

  .header-toppage {
    min-width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #fff;
    font-size: 14pt;
    .navbar {
      width: 100%;
      position: absolute;
      z-index: 2;
      .logo-wrapper {
        .logo {
          
          @media(max-width: 450px){ 
            height: 35px;
          }
          @media(min-width: 450px){ 
            height: 55pt;
          }
        }
        .logo-typo {
          height: 55pt;
          margin-left: 6.12pt;
        }
      }
      .menu-wrapper {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        flex: 1;
        height: 100%;
        .function-wrapper {
          display: inline-flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          padding: 0 10px;
          margin-bottom: 10pt;
          .ico-fb {
            width: 14.44pt;
            height: 14.44pt;
            filter: brightness(0) invert(1);
            -webkit-filter: brightness(0) invert(1);
          }
          .ico-tiktok {
            width: 20pt;
            height: 20pt;
            margin-left: 9pt;
            filter: brightness(0) invert(1);
            -webkit-filter: brightness(0) invert(1);
          }
          .ico-instagram {
            width: 20pt;
            height: 20pt;
            margin-left: 9pt;
            filter: brightness(0) invert(1);
            -webkit-filter: brightness(0) invert(1);
          }
          .ico-youtube {
            width: 20pt;
            height: 20pt;
            margin-left: 12pt;
            margin-right: 35.19pt;
            filter: brightness(0) invert(1);
            -webkit-filter: brightness(0) invert(1);
          }
          .exchange-lang-btn {
            width: 20pt;
            height: 13.81pt;
            text-align: center;
            line-height: 13.81pt;
            font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
            color: #FFFFFF;
            font-size: 10pt;
          }
          .lang-en {
            margin-right: 2pt;
            margin-top: 6pt;
          }
          .lang-vn {
            margin-right: 8pt;
            margin-top: 6pt;
          }
          .user-name-login {
            width: auto;
            margin-top: 8pt;
            margin-right: 5pt;
          }
          .user-name {
            width: 100pt;
            margin-top: 8pt;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .btn-login{
            height: 30pt;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 9pt;
            .ico-user {
              width: 20pt;
              height: 20pt;
              margin-right: 8pt;
              margin-top: -3pt;
              filter: brightness(0) invert(1);
              -webkit-filter: brightness(0) invert(1);
            }
            &:hover {
              .ico-user {
                background: none  ;
              }
            }
          }
          .btn-logout {
            span {
              margin-left: 8pt;
            }
          }
        }
        .menu {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          flex: 1;
          padding: 0 10pt;

          .dropbtn {
            background:none;
            font-size: 15pt;
            border: none;
            cursor: pointer;
            a {
              color: #FFFFFF;
              padding-right: 3pt;
            }
          }
          .dropdown {
            position: relative;
            display: inline-block;
            @include media-breakpoint-down(lg) {
              margin-bottom: 10pt;
            }
          }
          .dropdown-content {
            display: none;
            position: absolute;
            min-width: 160pt;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
          }
          .dropdown-content a {
            color: #FFFFFF;
            padding: 12px 16px;
            font-size: 15pt;
            text-decoration: none;
            display: block;
          }
          .dropdown-content a:hover {
            background-color: $theme-primary-hover-color;
          }
          .dropdown:hover .dropdown-content {
            display: block;
          }

          #navbarSupportedContent {
            .navbar-nav {
              margin-left: auto;
              @media (max-width: 768px) {
                margin-left: unset;
              }
              .nav-item {
                a {
                  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
                  color: #FFFFFF;
                  font-size: 15pt;
                }
              }
            }
          }

          #navbarSupportedContentMobile {
            .navbar-nav {
              margin-left: auto;
              @media (max-width: 768px) {
                margin-left: unset;
              }
              .nav-item {
                a {
                  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
                  color: #FFFFFF;
                  font-size: 15pt;
                }
              }
            }
          }

          .input-group {
            .search-input {
              height: 17.62pt;
              width: 116.69pt;
              border-radius: 0;
              font-size: 9pt;
              padding: 0 15pt 0 5pt;
              border: solid 1px #ffffff;
              background: none;
              &:active, &:focus {
                outline: none;
              }
            }
            .search-btn {
              height: 17.62pt;
              padding: 0;
              margin-left: -13pt;
              display: flex;
              flex-direction: column;
              justify-content: center;
              border: none;
              background: none;
              .search-icon {
                font-size: 9pt;
                margin-left: -20px;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .carousel-wrapper {
      position: relative;
      display: block;
      #carousel-text {
        line-height: 45pt;
        position: absolute;
        left: 40pt;
        top: 58pt;
        z-index: 2;
        .text-below {
          padding-left: 33pt;
          @media (max-width: 664.98px) {
            padding-left: 15pt;
          }
        }
        span {
          font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
          word-spacing: -4pt;
          color: #d5be65;
          font-size: 50pt;
          @media (max-width: 664.98px) {
            font-size: 28pt;
          }
        }
        @media (max-width: 664.98px) {
          position: absolute;
          top: 10pt;
          left: 21pt;
          z-index: 1;
          line-height: 20pt;
        }
      }
      #topCarousel {
        .carousel-inner {
          .carousel-item {
            width: 100%;
            aspect-ratio: 1044 / 610;
            overflow: hidden;
            object-fit: cover;
            transition: transform 2s ease, opacity .5s ease-out;
            @media (max-width: 1920px) {
              aspect-ratio: 1920 / 991;
            }
            img {
              width: 100%;
              height: 100%;
              &.d-block {
                animation: zoom-in-zoom-out 10s ease infinite;
              }
            }

            @keyframes zoom-in-zoom-out {
              0% {
                transform: scale(1.1, 1.1) translate(-5%, 0);
              }
              100% {
                transform: scale(1.1, 1.1) translate(0, 0);
              }
            }
          }

          .carousel-item:nth-child(2) {
            img {
              width: 100%;

              &.d-block {
                animation: zoom-in-zoom-out2 10s ease infinite;
              }
            }

            @keyframes zoom-in-zoom-out2 {
              0% {
                transform: scale(1.1, 1.1) translate(5%, 0);
              }
              100% {
                transform: scale(1.1, 1.1) translate(0, 0);
              }
            }
          }
        }
        .carousel-indicators {
          position: relative;
          height: 36.9pt;
          display: flex;
          align-items: center;
          [data-bs-target] {
            width: 10pt;
            height: 10pt;
            border: none;
            border-radius: 50%;
            background: #E1D5E7;
            &.active {
              background: #666666;
            }
          }
        }
        .carousel-control-prev, .carousel-control-next {
          padding-bottom: 36.9pt;
        }
      }
      .calendar {
        padding: 5pt;
        font-size: 7pt;
        position: absolute;
        right: 13pt;
        bottom: 60pt;
        background: #fff;
        z-index: 1;
        width: 170pt;
        height: 188pt;

        @media (max-width: 1199.98px) {
          width: 30%;
        }
        @media (max-width: 991.98px) {
          width: 100%;
          position: relative;
          right: 0;
          bottom: 0;
          padding: 0;
          margin-top: 10px;
        }
        .fc-header-toolbar {
          background: $theme-primary-color;;
          margin: 0;
          color: #fff;
          height: 30px;
        }
        .fc-toolbar-title {
          font-size: 10pt;
        }
        .fc-daygrid-day-top {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .fc-prev-button, .fc-next-button, .fc-gridButton-button, .fc-spacer-button {
          background: none;
          border: none;
        }
        .fc-daygrid-day-events {
          display: none;
        }
        .fc-col-header-cell {
          height: 23pt;
        }

        .fc-daygrid-day-top {
          justify-content: center;
          .fc-daygrid-day-number {
            width: 17px;
            height: 17px;
            display: inline-flex;
            aspect-ratio: 1;
            align-items: center;
            justify-content: center;
            margin-top: 7px;
            &.highlight {
              background: $theme-primary-hover-color;;
              border-radius: 50%;
              color: #fff;
            }
          }
        }
      }

      .menu-mobile-wrapper {
        align-items: flex-end;
        display: inline-flex;
        flex: 1;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        .menu {
          min-width: 300px !important;
          justify-content: flex-end !important;

          .dropbtn {
            background:none;
            font-size: 15pt;
            border: none;
            cursor: pointer;
            a {
              color: #A48037;
              padding-right: 3pt;
            }
          }
          .dropdown {
            position: relative;
            display: inline-block;
            @include media-breakpoint-down(lg) {
              margin-bottom: 10pt;
            }
          }
          .dropdown-content {
            display: none;
            z-index: 1;
          }
          .dropdown-content a {
            color: #A48037;
            padding: 12px 16px;
            font-size: 15pt;
            text-decoration: none;
            display: block;
          }
          .dropdown-content a:hover {
            background-color: $theme-primary-hover-color;
          }
          .dropdown:hover .dropdown-content {
            display: block;
          }
        }
      }

      .position-relative {
        position: relative !important;
      }
    }
  }

  #app {
    padding-left: 124.5pt;
    padding-right: 124.5pt;
    @include media-breakpoint-up(xxl) {
      padding-left: 124.5pt;
      padding-right: 124.5pt;
    }
    @media (max-width: 1399.98px) {
      padding-left: 50pt;
      padding-right: 50pt;
    }
    @media (max-width: 1199.98px) {
      padding-left: 0;
      padding-right: 0;
    }
    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: #fff;
      font-size: 14pt;
      .navbar {
        max-width: $theme-max-width;
        width: 100%;
        padding-bottom: 35pt;
        padding-top: 40pt;
        .logo-wrapper {
          .logo {
            height: 55pt;
          }
          .logo-typo {
            height: 55pt;
            margin-left: 6.12pt;
          }
        }
        .menu-wrapper {
          display: inline-flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
          flex: 1;
          height: 100%;
          .function-wrapper {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: 0 10px;
            margin-bottom: 10pt;
            .ico-fb {
              width: 14.44pt;
              height: 14.44pt;
            }
            .ico-tiktok {
              width: 20pt;
              height: 20pt;
              margin-left: 9pt;
            }
            .ico-instagram {
              width: 20pt;
              height: 20pt;
              margin-left: 9pt;
            }
            .ico-youtube {
              width: 20pt;
              height: 20pt;
              margin-left: 12pt;
              margin-right: 35.19pt;
            }
            .exchange-lang-btn {
              width: 20pt;
              height: 13.81pt;
              text-align: center;
              line-height: 13.81pt;
              font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
              color: black;
              background-color: #fff;
              font-size: 10pt;
            }
            .lang-en {
              margin-right: 2pt;
              margin-top: 6pt;
            }
            .lang-vn {
              margin-right: 8pt;
              margin-top: 6pt;
            }
            .user-name-login {
              width: auto;
              margin-top: 8pt;
              margin-right: 5pt;
            }
            .user-name {
              width: 100pt;
              margin-top: 8pt;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .btn-login{
              height: 30pt;
              background: #fff;
              color: #333;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 9pt;
              .ico-user {
                width: 18pt;
                height: 14.4pt;
                margin-right: 8pt;
              }
            }
            .btn-logout {
              span {
                margin-left: 8pt;
              }
            }
            .username-block {
              &:hover {
                background: #ffff;
                color: #333;
                .ico-user {
                  filter: invert(0);
                }
              }
            }
          }
          .menu {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            flex: 1;
            padding: 0 10pt;

            .dropbtn {
              background:none;
              font-size: 9pt;
              border: none;
              cursor: pointer;
              a {
                color: #0000008C;
                padding-right: 2pt;
              }
            }
            .dropdown {
              position: relative;
              display: inline-block;
              margin-bottom: 3pt;
              @include media-breakpoint-down(lg) {
                margin-bottom: 8pt;
              }
            }
            .dropdown-content {
              display: none;
              position: absolute;
              background-color: #f9f9f9;
              min-width: 160px;
              box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
              z-index: 1;
            }
            .dropdown-content a {
              color: #0000008C;
              padding: 12px 16px;
              font-size: 9pt;
              text-decoration: none;
              display: block;
            }
            .dropdown-content a:hover {
              background-color: #f1f1f1
            }
            .dropdown:hover .dropdown-content {
              display: block;
            }

            #navbarSupportedContent {
              .navbar-nav {
                .nav-item {
                  a {
                    font-size: 9pt;
                  }
                }
              }
            }
            #navbarSupportedContentMobile {
              .navbar-nav {
                .nav-item {
                  a {
                    font-size: 9pt;
                  }
                }
              }
            }
            .input-group {
              .search-input {
                height: 17.62pt;
                width: 116.69pt;
                border-radius: 0;
                font-size: 9pt;
                padding: 0 15pt 0 5pt;
                border: solid 1px $theme-primary-color;
                &:active, &:focus {
                  outline: none;
                }
              }
              .search-btn {
                height: 17.62pt;
                padding: 0;
                margin-left: -13pt;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border: none;
                background: none;
                .search-icon {
                  font-size: 9pt;
                  color: $theme-primary-color;
                }
              }
            }
          }
        }
      }

    }
    .footer {
      max-width: $theme-max-width;
      margin: auto;
      padding-top: 40pt;
      .h-line {
        height: 0.4pt;
        background: #B0941E;
      }
      .logo-spacer {
        height: 32pt;
        background: #fff;
        padding-left: 39.32pt;
        margin-bottom: 30pt;
        display: flex;
        align-items: center;
        .logo {
          height: 55pt;
        }
        .logo-typo {
          height: 55pt;
          margin-left: 6.12pt;
        }
        .company {
          display: inline-block;
          padding-left: 39.32pt;
          padding-right: 40pt;
          color: $theme-primary-color;
          @media (max-width: 664.98px) {
            padding-left: 12pt;
            padding-right: 12pt;
            line-height: 18px;
          }
          .company-name {
            font-size: 10pt;
            font-weight: bold;
          }
          .company-address {
            font-size: 9pt;
          }
          .company-phone {
            font-size: 9pt;
          }
        }
      }
      .site-links {
        padding: 0;
        margin: 30pt 0;
        @media (max-width: 576px) {
          width: 90%;
          padding-left: 15pt;
          margin: 15pt 0;
        }
        .underscore {
          color: $theme-primary-color;
          font-size: 12pt;
          font-family: Helvetica, Helvetica, Times, Baskerville, Georgia, serif;
        }
        .social-links {
          .function-wrapper {
            display: inline-flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            @media (max-width: 576px) {
              padding-left: 0pt;
            }
            .ico-fb {
              width: 18.75pt;
              height: 18.75pt;
            }
            .ico-tiktok {
              width: 20pt;
              height: 20pt;
              margin-left: 9pt;
            }
            .ico-youtube {
              width: 20pt;
              height: 20pt;
              margin-left: 12pt;
              margin-right: 15pt;
            }
          }
        }
        .one-line {
          white-space: nowrap;
        }
        .col-md-1 {
          @media (max-width: 540px) {
            flex: 1 1 25%;
          }
          @media (min-width: 541px) and (max-width: 1024px) {
            flex: 1 1 33%;
          }
          @media (min-width: 1025px) {
            flex: 1 1 auto;
          }
        }
      }
      .site-links.container {
        @media (min-width: 768px) {
          max-width: none;
        }
      }
      .copyright-vnso {
        color: $theme-primary-color;
        font-size: 11pt;
        font-family: Helvetica, Helvetica, Times, Baskerville, Georgia, serif;
        margin-top: 20pt;
        margin-bottom: 350pt;
        padding-left: 10pt;
      }
    }

    main {
      background: #fff;
      min-height: 50vh; //TODO: For testing only
      .top-page {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        .carousel-wrapper {
          position: relative;
          display: block;
          max-width: 791pt;
          #carousel-text {
            line-height: 45pt;
            position: absolute;
            left: 40pt;
            top: 58pt;
            z-index: 2;
            .text-below {
              padding-left: 33pt;
              @media (max-width: 664.98px) {
                padding-left: 15pt;
              }
            }
            span {
              font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
              word-spacing: -4pt;
              color: #d5be65;
              font-size: 50pt;
              @media (max-width: 664.98px) {
                font-size: 28pt;
              }
            }
            @media (max-width: 664.98px) {
              position: absolute;
              top: 10pt;
              left: 21pt;
              z-index: 1;
              line-height: 20pt;
            }
          }
          #topCarousel {
            .carousel-inner {
              .carousel-item {
                width: 100%;
                aspect-ratio: 791.9 / 351.1;
                overflow: hidden;
                transition: transform 2s ease, opacity .5s ease-out;

                img {
                  width: 100%;

                  &.d-block {
                    animation: zoom-in-zoom-out 10s ease infinite;
                  }
                }

                @keyframes zoom-in-zoom-out {
                  0% {
                    transform: scale(1.1, 1.1) translate(-5%, 0);
                  }
                  100% {
                    transform: scale(1.1, 1.1) translate(0, 0);
                  }
                }
              }

              .carousel-item:nth-child(2) {
                img {
                  width: 100%;

                  &.d-block {
                    animation: zoom-in-zoom-out2 10s ease infinite;
                  }
                }

                @keyframes zoom-in-zoom-out2 {
                  0% {
                    transform: scale(1.1, 1.1) translate(5%, 0);
                  }
                  100% {
                    transform: scale(1.1, 1.1) translate(0, 0);
                  }
                }
              }
            }
            .carousel-indicators {
              position: relative;
              height: 36.9pt;
              display: flex;
              align-items: center;
              [data-bs-target] {
                width: 10pt;
                height: 10pt;
                border: none;
                border-radius: 50%;
                background: #E1D5E7;
                &.active {
                  background: #666666;
                }
              }
            }
            .carousel-control-prev, .carousel-control-next {
              padding-bottom: 36.9pt;
            }
          }
          .calendar {
            padding: 5pt;
            font-size: 7px;
            position: absolute;
            right: 13pt;
            bottom: 60pt;
            background: #fff;
            z-index: 1;
            width: 109pt;
            height: 129.49pt;

            @media (max-width: 1199.98px) {
              width: 30%;
            }
            @media (max-width: 991.98px) {
              width: 100%;
              position: relative;
              right: 0;
              bottom: 0;
              padding: 0;
              margin-top: 10px;
            }
            .fc-header-toolbar {
              background: $theme-primary-color;;
              margin: 0;
              color: #fff;
              height: 30px;
            }
            .fc-toolbar-title {
              font-size: 0.4rem;
            }
            .fc-daygrid-day-top {
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            .fc-prev-button, .fc-next-button, .fc-gridButton-button, .fc-spacer-button {
              background: none;
              border: none;
            }
            .fc-daygrid-day-events {
              display: none;
            }
            .fc-col-header-cell {
              height: 20pt;
            }

            .fc-daygrid-day-top {
              justify-content: center;
              .fc-daygrid-day-number {
                width: 15px;
                height: 15.3px;
                display: inline-flex;
                aspect-ratio: 1;
                align-items: center;
                justify-content: center;
                margin: 2px;
                &.highlight {
                  background: $theme-primary-hover-color;;
                  border-radius: 50%;
                  color: #fff;
                }
              }
            }
          }
        }
        .gray-line {
          height: 1px;
          background: #999999;
          margin-bottom: 10pt;
          max-width: $theme-max-width;
          width: 100%;
        }
        .upcoming-concert-wrapper {
          margin-bottom: 18pt;
          margin-top: 55pt;
          .title {
            width: 100%;
            min-height: 30pt;
            text-align: center;
            color: #333333;
            font-size: 15pt;
            font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
          }
          .concert-slider-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 24.5pt;
            .concert-slider-inner {
              max-width: $theme-max-width - 78pt;
              @include media-breakpoint-down(sm) {
                max-width: calc(100vw - 78pt);
              }
              .concert-slider {
                min-width: 100%;
                .item {
                  display: inline-flex;
                  flex-direction: column;
                  align-items: center;
                  img {
                    width: 161.33pt;
                    height: 121pt
                  }
                  .event-date {
                    margin-top: 10pt;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    .date {
                      font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
                      font-size: 40pt;
                      font-weight: bold;
                      font-style: italic;
                      margin-right: 10pt;
                    }
                    .month-year-wrapper {
                      font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
                      font-size: 13pt;
                    }
                  }
                  .concert-type {
                    font-size: 14pt;
                    text-align: center;
                  }
                  .btn-buy-now {
                    width: 101.5pt;
                    height: 30pt;
                    background: $theme-primary-color;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10pt;
                    border-radius: 15pt;
                    .ico-ticket {
                      width: 20pt;
                      height: 20pt;
                      margin-right: 5pt;
                    }
                    span {
                      font-size: 11pt;
                    }
                    &:hover {
                      background: $theme-primary-hover-color;
                    }
                  }
                }
              }
            }
            .slider-controller {
              width: 78pt;
              padding-left: 2.5%;
              .nav-button {
                width: 58pt;
                height: 41.54pt;
                border: solid 1px #D5BE65;
                font-size: 29pt;
                color: #D5BE65;
                display: flex;
                justify-content: center;
                cursor: pointer;
                &.next {
                  margin-bottom: 22pt;
                }
                &:hover {
                  color: #fff;
                  background: #D5BE65;
                  border: solid 1px #fff;
                }
              }
            }
          }
        }
        .about-us-wrapper {
          background: #1A1A1A;
          color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          text-align: center;
          margin-top: 100pt;
          padding-bottom: 30pt;
          max-width: $theme-max-width;
          @include media-breakpoint-down(md) {
            margin-top: 10pt;
          }
          .header-img {
            width: 100%;
            max-height: 500px;
            @include media-breakpoint-down(md) {
              width: 100%;
              margin-top: 0;
            }
          }
          .title {
            font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
            color: $theme-primary-color;
            font-size: 20pt;
            min-height: 40pt;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10pt;
          }
          .about {
            max-width: $theme-content-max-width;
            text-align: justify;
            font-size: 11pt;
          }
          .navigate {
            max-width: $theme-content-max-width;
            display: flex;
            width: 100%;
            justify-content: flex-end;
            @include media-breakpoint-down(md) {
              margin-right: 10pt;
            }
            a {
              text-decoration: none;
              font-weight: bold;
              color: #fff;
              display: flex;
              align-items: center;
              font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
              cursor: pointer;
              .bi {
                margin-left: 10pt;
                font-size: 18pt;
                background: $theme-primary-color;
                border-radius: 50%;
                color: #fff;
                width: 40pt;
                height: 40pt;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  background: $theme-primary-hover-color;
                }
              }
            }
          }
        }
        .ticket-wrapper {
          max-width: $theme-max-width;
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: row;
          position: relative;
          min-height: 221pt;
          background-image: url("../img/Anh_chim_phan_ve.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          .ticket-bg {
            background: #E6E6E6;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.9;
          }
          .ticket-inner {
            position: relative;
            width: 100%;
            margin-left: 15%;
            color: #333333;
            padding-top: 38pt;
            padding-bottom: 57pt;
            display: flex;
            flex-direction: row;
            align-items: center;
            @media (max-width: 480px) {
              margin: 0;
            }
            @include media-breakpoint-down(lg) {
              max-width: 95%;
              padding-left: 10pt;
              padding-right: 10pt;
            }
            @include media-breakpoint-down(md) {
              flex-direction: column;
              max-width: 100%;
              padding-left: 10pt;
              padding-right: 10pt;
            }
            .left-col {
              max-width: 50%;
              margin-right: 11pt;
              margin-bottom: 11pt;
              @include media-breakpoint-down(md) {
                max-width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin-bottom: 57pt;
              }
              .title {
                font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
                font-size: 18pt;
                height: 40pt;
              }
              .description {
                font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
                font-size: 11pt;
              }
              .btn-buy-ticket {
                width: 90pt;
                height: 25pt;
                background: $theme-primary-color;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10pt;
                border-radius: 15pt;
                .ico-ticket {
                  width: 14pt;
                  height: 14pt;
                  margin-right: 5pt;
                }
                span {
                  font-size: 10pt;
                }
                &:hover {
                  background: $theme-primary-hover-color;
                }
                &__custom {
                  display: inline-flex;
                  padding: 0 10pt;
                  width: auto;
                }
              }
            }
            .right-col {
              max-width: 50%;
              width: 100%;
              margin-left: 40pt;
              @media (max-width: 480px) {
                margin-left: 0pt;
              }
              .btn-ticket {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                min-width: 130pt;
                width: 130pt;
                height: 60pt;
                background: #fff;
                border-radius: 18pt;
                margin-bottom: 11pt;
                cursor: pointer;
                &:hover {
                  background: $theme-primary-color;
                  span {
                    color: #fff;
                  }
                  img {
                    filter: invert(1);
                  }
                }
                span {
                  font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
                  font-size: 15pt;
                  text-decoration: none;
                  color: #333333;
                }
                .icon-seat {
                  width: 40pt;
                  height: 40pt;
                  margin-top: -10px;
                }
                .icon-ticket {
                  width: 24.5pt;
                  height: 24.5pt;
                }
                .icon-student {
                  width: 20pt;
                  height: 20pt;
                  margin-bottom: 5pt;
                }
                .icon-membership {
                  width: 40pt;
                  height: 40pt;
                  margin-top: -10px;
                }
                .icon-title {
                  margin-top: -10px;
                }
              }
              .row1 {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                @media (max-width: 370px) {
                  flex-direction: column;
                  margin-bottom: 0;
                }
                & a:first-child {
                  margin-right: 10pt;
                  @media (max-width: 370px) {
                    margin-right: 0;
                  }
                }
              }
              .row2 {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                @media (max-width: 370px) {
                  flex-direction: column;
                }
                & a:first-child {
                  margin-right: 10pt;
                  @media (max-width: 370px) {
                    margin-right: 0;
                  }
                }
              }
              &__ticket-type {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 11pt;
                justify-items: center;
                .right-col__item {
                  margin-bottom: 0;
                  &:nth-child(odd) {
                    justify-self: end;
                  }

                  &:nth-child(even) {
                    justify-self: start;
                  }
                }
              }
            }
            .final-col-sp {
              @include media-breakpoint-up(lg) {
                display: none;
              }
              min-width: 10%;
              padding-left: 30pt;
            }
          }
          .final-col-img {
            font-size: 18pt;
            background: #FFFFFF;
            border-radius: 50%;
            color: #fff;
            height: 44pt;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50pt;
            img {
              width: 24.5pt;
            }
          }
          .final-col-pc {
            @include media-breakpoint-down(lg) {
              display: none;
            }
            z-index: 1;
            position: absolute;
            right: 17px;
            top: 93pt;
          }
        }
        .support-wrapper {
          display: flex;
          justify-content: center;
          flex-direction: row;
          position: relative;
          min-height: 221pt;
          background-image: url("../img/Anh_chim_phan_ho_tro.jpg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          max-width: $theme-max-width;
          width: 100%;
          @include media-breakpoint-down(sm) {
            padding-left: 0;
          }
          .support-bg {
            background: #E6E6E6;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.9;
          }
          .support-inner {
            width: 100%;
            position: relative;
            color: #333333;
            padding-top: 38pt;
            padding-bottom: 57pt;
            margin-left: 15%;
            display: flex;
            flex-direction: row;
            align-items: center;
            @include media-breakpoint-down(lg) {
              max-width: 95%;
              padding-left: 10pt;
              padding-right: 10pt;
            }
            @include media-breakpoint-down(md) {
              flex-direction: column;
              max-width: 100%;
              padding-left: 10pt;
              padding-right: 10pt;
            }
            @include media-breakpoint-down(md) {
              margin-left: 0;
            }
            .left-col {
              margin-bottom: 11pt;
              @include media-breakpoint-down(md) {
                max-width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
              }
              .title {
                font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
                font-size: 18pt;
                height: 40pt;
              }
              .description {
                font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
                font-size: 11pt;
              }
              .btn-more-info {
                width: 90pt;
                height: 25pt;
                padding-left: 10pt;
                padding-right: 10pt;
                height: 25pt;
                background: $theme-primary-color;
                color: #fff;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-top: 10pt;
                border-radius: 15pt;
                .ico-info {
                  width: 18pt;
                  height: 16  pt;
                  margin-right: 5pt;
                }
                span {
                  font-size: 10pt;
                }
                &:hover {
                  background: $theme-primary-hover-color;
                }
              }
            }
            .right-col {
              max-width: 45%;
              width: 100%;
            }
          }
        }
      }
      .about-us-page {
        max-width: $theme-max-width;
        width: 100%;
        margin: 0 auto;
        .about-img {
          width: 100%;
          height: auto;
        }
        .h-line {
          height: 0.4pt;
          background-color: #A48037;
          margin-top: 40pt;
          margin-bottom: 20pt;
        }
        .top-wrapper {
          @include media-breakpoint-down(xl) {
            flex-direction: column-reverse;
          }
          font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
          .top-wrapper-item {
            @include media-breakpoint-down(xl) {
              margin-top: 10pt;
              padding-left: 25pt;
            }
            .c-masthead-title {
              font-size: 17pt;
            }
            .c-masthead-description {
              font-size: 13pt;
              max-width: 295pt;
            }
          }
          .right {
            display: flex;
            justify-content: flex-end;
            @include media-breakpoint-down(sm) {
              justify-content: center;
            }
            .about-img {
              @include media-breakpoint-down(xl) {
                width: 100%;
                height: 100%;
              }
              width: 291.12pt;
              height: 218pt;
            }
          }
        }
        .bottom-page {
          font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
          .bottom-page-item {
            color: black;

            .bottom-page-item-title {
              width: 100%;
              display: flex;
              justify-content: left;
              @include media-breakpoint-down(xl) {
                max-width: 241.77pt;
              }
              span {
                font-size: 12pt;
                font-weight: bold;
              }
              p {
                font-size: 11pt;
              }
            }
            img {
              width: 251.77pt;
              height: 160pt;
              object-fit: cover;
            }
            .btn-more-info {
              width: 74px;
              height: 26px;
              align-items: center;
              border: 1px solid $theme-primary-color;
              border-radius: 15pt;
              color: $theme-primary-color;
              display: inline-flex;
              justify-content: center;
              span {
                font-family: Verdana,Geneva,sans-serif;
                font-size: 9pt;
              }
            }
            .btn-more-info:hover {
              background: $theme-primary-color;
              color: #fff;
            }
          }
        }
        .h-line-smaller {
          height: 0.4pt;
          background-color: #A48037;
          margin: 20pt 0;
        }
        .about-us-page-text-link {
          font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
          font-size: 13pt;
          @include media-breakpoint-up(xl) {
            display: flex;
          }
          .about-us-page-link-item {
            @include media-breakpoint-up(xl) {
              width: 50%;
            }
          }
          a {
            color: $theme-primary-color;
          }
        }
      }
      .concert-moreinfo-section {
        max-width: $theme-max-width;
        width: 100%;
        margin: 0 auto;
        .h-line {
          height: 1pt;
          background-color: #B0941E;
          margin: 10pt 0;
        }
        .concert-info-banner {
          padding: 20pt 0;
          align-items: center;
          @include media-breakpoint-down(xl) {
            flex-direction: column-reverse;
            align-items: unset;
          }
          .concert-info-banner-description {
            padding-left: 78pt;
            @include media-breakpoint-down(xl) {
              padding-left: 39.32pt;
            }
            .concert-info-banner-description-item {
              font-family: 'NeuzeitOffice', sans-serif;
              .concert-info-banner-description-item-vol {
                p {
                  font-size: 11pt;
                }
                @include media-breakpoint-down(xl) {
                  margin-top: 10pt;
                }
              }
              .concert-info-banner-description-item-author {
                margin: 24pt 0;
                p {
                  margin-bottom: 0;
                }
                @media (max-width: 1650px) {
                  margin: 14pt 0;
                }
                @include media-breakpoint-down(xl) {
                  margin: 10pt 0;
                }
              }
              .concert-info-banner-description-item-place {
                font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
                .concert-info-banner-description-item-place-date p {
                  font-size: 11pt;
                  font-weight: bold;
                  color: red;
                }
                .concert-info-banner-description-item-place-address {
                  font-size: 11pt;
                  .ico-location {
                    width: 16pt;
                    height: 16pt;
                  }
                  span {
                    margin-left: 14pt;
                  }
                }
              }
              .concert-info-banner-description-item-button {
                margin-top: 40pt;
                .btn-buy-now {
                  width: 74pt;
                  height: 26pt;
                  background: $theme-primary-color;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                  border-radius: 28px;
                  .ico-ticket {
                    width: 12pt;
                    height: 12pt;
                  }
                  span {
                    font-size: 9pt;
                  }
                  &:hover {
                    background: $theme-primary-hover-color;
                  }
                }
              }
              .link-download-concert-flyer {
                margin-top: 10pt;
                a {
                  text-decoration: underline;
                  color: #666666;
                  font-size: 11pt;
                }
              }
            }
          }
          .concert-info-banner-image {
            .d-block {
              width: 367.12pt;
              height: 245.69pt;
              @include media-breakpoint-down(xl) {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .concert-info-recommend {
          font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
          margin-top: 40px;
          padding-left: 39.32pt;
          padding-right: 10px;
        }
        .title {
          font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
          width: 100%;
          margin-top: 60pt;
          min-height: 30pt;
          color: #B0941E;
          font-size: 13pt;
          margin-left: 39.32pt;
          font-weight: 600;
        }
        .gray-line {
          height: 1px;
          background: #999999;
          margin-bottom: 10pt;
        }
        .upcoming-concert-wrapper {
          margin-bottom: 18pt;
          .title {
            width: 100%;
            min-height: 30pt;
            text-align: center;
            color: #333333;
            font-size: 15pt;
            font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
          }
          .concert-slider-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 24.5pt;
            .concert-slider-inner {
              max-width: $theme-max-width - 78pt;
              @include media-breakpoint-down(sm) {
                max-width: calc(100vw - 78pt);
              }
              .concert-slider {
                min-width: 100%;
                .item {
                  display: inline-flex;
                  flex-direction: column;
                  align-items: center;
                  img {
                    width: 160pt;
                    height: 120pt
                  }
                  .event-date {
                    margin-top: 10pt;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    .date {
                      font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
                      font-size: 40pt;
                      font-weight: bold;
                      font-style: italic;
                      margin-right: 10pt;
                    }
                    .month-year-wrapper {
                      font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
                      font-size: 13pt;
                    }
                  }
                  .concert-type {
                    font-size: 14pt;
                    text-align: center;
                  }
                  .concert-btn-action {
                    width: 160pt;
                    .btn-buy-now {
                      width: 74pt;
                      height: 26pt;
                      background: $theme-primary-color;
                      color: #fff;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-top: 10pt;
                      border-radius: 15pt;
                      .ico-ticket {
                        width: 11pt;
                        height: 11pt;
                        margin-right: 5pt;
                      }
                      span {
                        font-size: 9pt;
                      }
                      &:hover {
                        background: $theme-primary-hover-color;
                      }
                    }
                    .btn-more-info {
                      width: 74pt;
                      height: 26pt;
                      background: #fff;
                      color: $theme-primary-color;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-top: 10pt;
                      border-radius: 15pt;
                      border: 1px solid $theme-primary-color;
                      .ico-ticket {
                        width: 20pt;
                        height: 20pt;
                        margin-right: 5pt;
                      }
                      span {
                        font-size: 9pt;
                      }
                      &:hover {
                        background: $theme-primary-hover-color;
                      }
                    }
                  }
                }
              }
            }
            .slider-controller {
              width: 78pt;
              padding-left: 2.5%;
              .nav-button {
                width: 58pt;
                height: 41.54pt;
                border: solid 1px #D5BE65;
                font-size: 29pt;
                color: #D5BE65;
                display: flex;
                justify-content: center;
                cursor: pointer;
                &.next {
                  margin-bottom: 22pt;
                }
                &:hover {
                  color: #fff;
                  background: #D5BE65;
                  border: solid 1px #fff;
                }
              }
            }
          }
        }
      }
      .concert-page {
        max-width: $theme-max-width;
        width: 100%;
        margin: 0 auto;
        .concert-page-banner {
          align-items: center;
          background: #f4f4ec;
          padding: 25pt;
          @include media-breakpoint-down(xl) {
            flex-direction: column-reverse;
            align-items: unset;
          }
          .concert-page-banner-description {
            padding-left: 25pt;
            .concert-page-banner-description-item {
              width: 68%;
              font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
              .concert-page-banner-description-item-season {
                span {
                  font-size: 20pt;
                  word-break: break-word;
                }
              }
              .concert-page-banner-description-item-schedule {
                span {
                  font-size: 15pt;
                }
              }
              .concert-page-banner-description-item-btn {
                .btn-view-event {
                  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
                  width: 120pt;
                  height: 40pt;
                  background: $theme-primary-color;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                  border-radius: 6pt;
                  .ico-event {
                    width: 22pt;
                    height: 22pt;
                    margin-right: 5pt;
                  }
                  span {
                    font-size: 12pt;
                  }
                  &:hover {
                    background: $theme-primary-hover-color;
                  }
                }
              }
            }
          }
          .concert-page-banner-image {
            .d-block {
              width: 433.34pt;
              height: 290pt;
              @include media-breakpoint-down(xl) {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .past-concert-title {
          margin-top: 20pt;
          margin-left: 10pt;
          margin-bottom: -20pt;
          font-size: 15pt;
          font-weight: bold;
          color: $theme-primary-color;
        }
        .h-line {
          height: 0.5pt;
          background-color: #0a0e14;
          margin: 2pt 0;
        }
        .bottom-page {
          margin-bottom: 40px;
          .bottom-page-item {
              display: inline-flex;
              flex-direction: column;
              align-items: center;
              img {
                width: 160pt;
                height: 120pt
              }
              .event-date {
                margin-top: 10pt;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                .date {
                  font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
                  font-size: 40pt;
                  font-weight: bold;
                  font-style: italic;
                  margin-right: 10pt;
                }
                .month-year-wrapper {
                  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
                  font-size: 13pt;
                }
              }
              .concert-type {
                font-size: 14pt;
                text-align: center;
              }
              .concert-btn-action {
                width: 160pt;
                .btn-buy-now {
                  width: 74pt;
                  height: 26pt;
                  background: $theme-primary-color;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 10pt;
                  border-radius: 15pt;
                  .ico-ticket {
                    width: 12pt;
                    height: 12pt;
                    margin-right: 5pt;
                  }
                  span {
                    font-size: 9pt;
                  }
                  &:hover {
                    background: $theme-primary-hover-color;
                  }
                }
                .btn-more-info {
                  width: 74pt;
                  height: 26pt;
                  background: #fff;
                  color: $theme-primary-color;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 10pt;
                  border-radius: 15pt;
                  border: 1pt solid $theme-primary-color;
                  span {
                    font-size: 9pt;
                  }
                  &:hover {
                    background: $theme-primary-hover-color;
                  }
                }
              }
            }
        }
        .btn-action-pagination {
          .btn-action {
            width: 74pt;
            height: 26pt;
            background: $theme-primary-color;
            border: 1pt solid $theme-primary-color;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-top: 10pt;
            border-radius: 15pt;
            span {
              font-size: 9pt;
            }
            &:hover {
              background: $theme-primary-hover-color;
            }
          }
        }
      }
      .profile-page {
        max-width: $theme-max-width;
        width: 100%;
        margin: 0 auto;
        font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
        .profile-page-banner {
          padding: 20pt 0;
          @include media-breakpoint-down(xl) {
            flex-direction: column-reverse;
            align-items: unset;
          }
          .profile-page-banner-description {
            .profile-page-banner-description-item {
              @include media-breakpoint-down(xl) {
                margin: 20pt 0 0 20pt;
              }
              .profile-page-banner-description-item-title {
                font-size: 17pt;
              }
              .profile-page-banner-description-item-description {
                max-width: 290pt;
                font-size: 14pt;
              }
            }
          }
          .profile-page-banner-image {
            .d-block {
              width: 329.69pt;
              height: 220pt;
              object-fit: cover;
            }
          }
        }
        .profile-page-description {
          margin: 20pt;
          @include media-breakpoint-down(xl) {
            flex-direction: column;
            align-items: unset;
          }
          .profile-page-description-text {
            text-align: justify;
            p {
              font-size: 11pt;
              @include media-breakpoint-up(xl) {
                max-width: 350pt;
              }
            }
            span {
              display: inline-block;
              line-height: 2;
            }
          }
          .profile-page-description-download-btn {
            padding: 10pt;
            background-color: #F5F5F5;
            .profile-page-description-download-btn-box {
              font-size: 11pt;
              .profile-page-description-download-btn-box-link {
                ul {
                  margin-top: 10pt
                }
                a {
                  text-decoration: underline;
                  color: red;
                }
              }
            }
          }
          &-head {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10pt;
            padding-right: 20pt;
            @include media-breakpoint-down(sm) {
              flex-direction: column-reverse;
              padding-right: 0;
            }
          }
          &__establishment {
            text-align: justify;
          }
        }
        .h-line {
          height: 0.4pt;
          background-color: #A48037;
          margin: 10pt 0;
        }
        .h-line-smaller {
          height: 0.4pt;
          background-color: #A48037;
          margin-bottom: 20pt;
        }
        .profile-page-text-link {
          font-size: 13pt;
          @include media-breakpoint-up(xl) {
            display: flex;
          }
          .profile-page-link-item {
            @include media-breakpoint-up(xl) {
              width: 50%;
            }
          }
          a {
            color: $theme-primary-color;
          }
        }
      }
      .overview-page {
        max-width: $theme-max-width;
        width: 100%;
        margin: 0 auto;
        font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
        .overview-page-banner-item-title {
          font-size: 15pt;
        }
        .overview-page-banner {
          padding: 20pt 0;
          @include media-breakpoint-down(xl) {
            flex-direction: column-reverse;
            align-items: unset;
          }
          .overview-page-banner-description {
            .overview-page-banner-description-item {
              @include media-breakpoint-down(xl) {
                margin: 20pt 0 0 20pt;
              }
              .overview-page-banner-description-item-description {
                max-width: 290pt;
                font-size: 14pt;
              }
            }
          }
          .overview-page-banner-image {
            width: 329.69pt;
            height: 220pt;
            .d-block {
              width: 100%;
            }
          }
        }
        .overview-page-description {
          margin: 20pt 100pt;
          @include media-breakpoint-down(xl) {
            margin: 10pt;
          }
          .table-content {
            font-family: Helvetica;
            td {
              vertical-align: middle;
              font-size: 12pt;
            }
            .table-active {
              background-color: #f8f4f4;
            }
          }
          tr {
            @include media-breakpoint-up(xl) {
              td:first-child {
                width: 230pt;
                height: 50pt;
              }
              td:last-child {
                width: 420pt;
                height: 50pt;
              }
            }
          }
          .table-main-content {
            text-align: justify;
            tr {
              td {
                vertical-align: middle;
                font-size: 14pt;
              }
            }
            tr {
              @include media-breakpoint-up(xl) {
                td:first-child {
                  width: 110pt;
                  height: 50pt;
                }
                td:last-child {
                  width: 447pt;
                  height: 50pt;
                }
              }
            }
          }
        }
        .h-line {
          height: 2pt;
          background-color: #0a0e14;
          margin: 10pt 0;
        }
        .h-line-smaller {
          height: 0.4pt;
          background-color: $theme-primary-color;
          margin-top: 20pt;
          margin-bottom: 20pt;
        }
        .overview-page-text-link {
          font-size: 13pt;
          margin-bottom: 20pt;
          @include media-breakpoint-up(xl) {
            display: flex;
          }
          .overview-page-link-item {
            @include media-breakpoint-up(xl) {
              width: 50%;
            }
          }
          a {
            color: $theme-primary-color;
          }
        }
        .contacts-ticket-distributor {
          border: 2pt solid;
          border-color: #999999;
          font-size: 14pt;
          font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
          padding: 10pt;
        }
        &__content {
          font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
          font-size: 12pt;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 15pt;
          @include media-breakpoint-down(sm) {
            align-items: start;
          }
          &-info {
            > span:first-child {
              font-weight: bold;
              color: #A48037;
            }
          }
          div {
            min-width: 450pt;
            @include media-breakpoint-down(sm) {
              min-width: unset;
            }
          }
        }
      }
      .page {
        max-width: $theme-max-width;
        width: 100%;
        margin: 0 auto;
        .hr-line {
          height: 0.4pt;
          background-color: $theme-primary-color;
          margin: 15pt 0;
        }
        .page-title {
          margin: 20pt;
          font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
          font-size: 17pt;
          .page-detail-title {
            width: 62%;
            font-size: 13pt;
            color: #000000;
          }
        }
        .page-title-support {
          margin-top: 20pt;
          font-family: Verdana, Geneva, sans-serif;
          font-size: 15pt;
          margin-left: 40pt;
          @media (max-width: 630px) {
            margin-left: 20pt;
          }
          .title-support {
            font-weight: bold;
            color: $theme-primary-color;
            width: 80%;
          }
          .page-detail-title {
            font-size: 11pt;
            color: #333333;
            height: 100pt;
            width: 90%;
          }
          .page-title-corporate {
            margin-top: 80pt;
            font-size: 11pt;
          }
        }
        .page-title-member {
          display: flex;
          margin-left: 70pt;
          margin-top: 20pt;
          margin-bottom: 20pt;
          font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
          font-size: 17pt;
          @media (max-width: 630px) {
            flex-direction: column;
            margin-left: 20pt;
            margin-top: 0;
          }
          .page-detail-title-member {
            margin-left: 100pt;
            width: 45%;
            font-size: 13pt;
            color: #333333;
            @media (max-width: 630px) {
              margin-left: 25pt;
              width: 70%;
            }
          }
        }
        .page-content-details {
          max-width: $theme-max-width;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          padding-top: 40pt;
          padding-bottom: 100pt;
          padding-left: 80pt;
          font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
          @media (max-width: 630px) {
            flex-direction: column-reverse;
            padding-top: 10pt;
            padding-bottom: 50pt;
            padding-left: 20pt;
            padding-right: 20pt;
          }
          .content-title {
            width: 300pt;
            margin-top: 40pt;
            margin-right: 70pt;
            @media (max-width: 630px) {
              width: 100%
            }
            h3 {
              font-size: 17pt;
            }
            h4 {
              font-size: 16pt;
            }
            p {
              margin-top: 20pt;
              font-size: 12pt;
              color: #333333;
            }
          }
          .content-img {
            @media (max-width: 630px) {
              width: 100%;
            }
            img {
              width: 268.97pt;
              height: 225pt;
              @media (max-width: 630px) {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .page-content {
          .list-title {
            font-family: Helvetica, Helvetica, Times, Baskerville, Georgia, serif;
            margin: 20pt;
            font-size: 17pt;
            font-weight: bold;
            .title {
              color: $theme-primary-color;
            }
            .page-items {
              max-width: $theme-max-width;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              .items-img {
                margin: 10pt 0;
                margin-right: 20pt;
                width: 224pt;
                height: auto;
                font-size: 11pt;
                img {
                  margin-bottom: 10pt;
                  width: 100%;
                  height: 140pt;
                  object-fit: cover;
                }
              }
            }
          }
        }
        .h-line-smaller {
          height: 0.4pt;
          background-color: $theme-primary-color;
          margin: 20pt 0;
        }
        .page-text-link {
          font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
          font-size: 13pt;
          @include media-breakpoint-up(xl) {
            display: flex;
          }
          .page-link-item {
            @include media-breakpoint-up(xl) {
              width: 50%;
            }
          }
          a {
            color: $theme-primary-color;
          }
        }
        .items-img-support {
          max-width: $theme-max-width;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          @include media-breakpoint-down(xl) {
            flex-wrap: wrap;
          }
          @media (max-width: 630px) {
            flex-direction: column;
          }
            img {
              margin: 10pt;
            }
            .acecook {
              width: 222.97pt;
              height: 110pt;
            }
            .Toyota {
              width: 165pt;
              height: 100pt;
            }
            .Eneos {
              width: 80pt;
            }
            .Mitsubishi {
              width: 200pt;
              height: 45pt;
            }
            .Sojitz {
              width: 106.8pt;
              height: 59.25pt;
            }
            .Canon {
              width: 115pt;
              height: 45pt;
            }
            .Bitexco {
              width: 102.21pt;
              height: 140pt;
            }
            .airline {
              width: 100pt;
              height: 60pt;
            }
            .Gaconsultants {
              width: 100pt;
              height: 70pt;
            }
            .Ajinomoto {
              width: 129.81pt;
              height: 92.72pt;
            }
            .Blackstone {
              width: 132.64pt;
              height: 132.64pt;
            }
            .Metropole {
              width: 90pt;
              height: 100pt;
            }
            .Roygent {
              width: 200pt;
              height: 64.38pt;
              object-fit: cover;
            }
            .Hotelduparc {
              width: 100pt;
              height: 100pt;
              object-fit: cover;
            }
            .Lotte {
              width: 100pt;
              height: 100pt;
            }
            .NTD {
              width: 134.21pt;
              height: 23.82pt;
            }
            .UNFPA {
              width: 130pt;
              height: 130pt;
            }
            .Austrian {
              width: 95pt;
              height: 59.5pt;
              object-fit: cover;
            }
            .GEOTHEINSTIUT {
              width: 107pt;
              height: 50pt;
            }
            .Foundation {
              width: 87.32pt;
              height: 68pt;
            }
            .Institut {
              width: 116.26pt;
              object-fit: cover;
            }
            .NHKSO {
              width: 90pt;
              height: 90pt;
            }
            .sunrock {
              width: 123.08pt;
              height: 40pt;
            }
            .lotte-hotel {
              width: 150pt;
            }
            .austrian-embassy-hanoi {
              width: 150pt;
            }
          }
        .items-img-support-2 {
          display: flex;
          flex-direction: row;
          align-items: center;
          text-align: center;
          @media (max-width: 630px) {
            flex-direction: column;
          }
          img {
            margin: 20pt;
          }
          .Mitsubishi {
            width: 250pt;
            height: 48pt;
          }
          .Sojitz {
            width: 106.8pt;
            height: 59.25pt;
          }
          .Canon {
            width: 141.98pt;
            height: 50pt;
          }
          .Eneos {
            width: 95.64pt;
            height: 95pt;
          }
          .airline {
            width: 142.53pt;
            height: 80.03pt;
          }
          .Gaconsultants {
            width: 102.98pt;
            height: 80pt;
          }
        }

        .items-img-support-title {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            .supportBy {
              text-align: left;
              padding-left: 20pt;
            }
            img {
              margin: 0pt 20pt;
            }
            .Vymi {
              width: 131.25pt;
              height: 60pt;
            }
            .vy {
              width: 102.29pt;
              height: 60pt;
            }
        }
        .items-img-support-title-code {
          .support-by {
            text-align: center;
            padding-left: 20pt;
          }
          .img-support-by {
            text-align: center;
            img {
              margin: 0pt 20pt;
            }
            .monstarlab {
              width: 119.17pt;
              height: 53pt;
            }
            .jtest {
              width: 89.95pt;
              height: 56.58pt;
              margin-top: 1pt;
              object-fit: cover;
            };
          }
        }

        .profile-page-description-download-btn {
          margin-top:40pt;
          margin-left: 60pt;
          width: 655.03pt;
          height: auto;
          @media (max-width: 630px) {
            width: 100%;
            margin-top:20pt;
            margin-left: 0;
          }
          .profile-page-description-download-btn-box {
            font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
            padding: 0 20pt;
            font-size: 11pt;
            .profile-page-description-download-btn-box-link {
              margin-top: 10pt;
              font-family: Verdana, Geneva, sans-serif;
              a {
                text-decoration: underline;
                color: red;
              }
            }
          }
        }
        .profile-page-description-image {
          img {
            width: 100%;
          }
        }
        .page-privacy {
          margin: 0pt 53pt;
          .page-title-privacy {
            font-family: Verdana, Geneva, sans-serif;
            font-size: 13pt;
            font-weight: bold;
          }
          .page-detail-privacy {
            font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
            font-size: 11pt;
            .title-content-privacy {
              font-weight: bold;
              margin: 0;
            }
            ul {
              padding-left: 1rem;
            }
          }
        }
      }
      .ticket-page {
        max-width: $theme-max-width;
        width: 100%;
        margin: 0 auto;
        .concert-info-banner {
          padding: 20pt 0;
          align-items: center;
          @include media-breakpoint-down(xl) {
            flex-direction: column-reverse;
            align-items: unset;
          }
          .concert-info-banner-description {
            @include media-breakpoint-down(xl) {
              padding-left: 39.32pt;
            }
            .concert-info-banner-description-item {
              font-family: 'NeuzeitOffice', sans-serif;
              .concert-info-banner-description-item-vol {
                p {
                  font-weight: bold;
                  color: $theme-primary-color;
                  font-size: 11pt;
                }
                @include media-breakpoint-down(xl) {
                  margin-top: 10pt;
                }
              }
              .concert-info-banner-description-item-author {
                margin: 24pt 0;
                p {
                  margin-bottom: 0;
                }
                @media (max-width: 1650px) {
                  margin: 14pt 0;
                }
                @include media-breakpoint-down(xl) {
                  margin: 10pt 0;
                }
              }
              .concert-info-banner-description-item-place {
                font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
                .concert-info-banner-description-item-place-date p {
                  font-size: 11pt;
                  font-weight: bold;
                  color: #666666
                }
                .concert-info-banner-description-item-place-address {
                  font-size: 11pt;
                  .ico-location {
                    width: 16pt;
                    height: 16pt;
                  }
                  span {
                    margin-left: 14pt;
                  }
                }
              }
              .concert-info-banner-description-item-button {
                margin-top: 40pt;
                .btn-buy-now {
                  width: 74pt;
                  height: 26pt;
                  background: $theme-primary-color;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                  border-radius: 28px;
                  .ico-ticket {
                    width: 12pt;
                    height: 12pt;
                  }
                  span {
                    font-size: 9pt;
                  }
                  &:hover {
                    background: $theme-primary-hover-color;
                  }
                }
              }
              .link-download-concert-flyer {
                margin-top: 10pt;
                a {
                  text-decoration: underline;
                  color: #666666;
                  font-size: 11pt;
                }
              }
            }
          }
          .concert-info-banner-image {
            justify-content: end;
            @media (max-width: 664.98px) {
              justify-content: center;
              margin: 0 10pt;
            }
            .d-block {
              margin: 20pt;
              width: 367.12pt;
              height: 245.69pt;
              @include media-breakpoint-down(xl) {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .ticket-page-title {
          font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
          margin: 20pt 0;
          font-size: 30pt;
        }
        .ticket-page-detail {
          .ticket-page-detail-title {
            margin-top: 60pt;
            font-weight: bold;
            font-size: 14pt;
            color: #B0941E;
            @media (max-width: 664.98px) {
              margin-left: 10pt;
            }
          }
          .h-line {
            height: .4pt;
            background-color: #B0941E;
            margin: 10pt 0;
          }
          .ticket-page-detail-item {
            .h-line {
              height: .4pt;
              background-color: #0a0e14;
              margin: 10pt 0;
            }
            .ticket-page-detail-item-block {
              .ticket-page-detail-item-block-img {
                img {
                  width: 100%;
                }
              }
              .ticket-page-detail-item-block-text {
                margin-left: 10pt;
                .ticket-page-detail-item-block-text-name {
                  font-size: 15pt;
                  p {
                    margin-bottom: 0;
                  }
                }
                .ticket-page-detail-item-block-text-date-place {
                  color: #9E9E9E;
                }
              }
            }
          }
        }
        .ticket-page-action-block {
          display: flex;
          justify-content: center;
          flex-direction: row-reverse;
          @media (max-width: 664.98px) {
            display: block;
          }
          .ticket-page-action-box {
            margin-bottom: 20pt;
            width: 60%;
            @media (max-width: 664.98px) {
              width: 100%;
              margin-top: 70pt;
              margin-left: 10pt;
            }
            .student_ticket {
              font-size: 12pt;
              color: #666666;
            }
            .ticket-page-action-box-title {
              margin: 10pt 0;
              font-size: 12pt;
              font-weight: bold;
              color: #B0941E
            }
            table {
              text-align: center;
              color: #666666;
              th {
                font-size: 13pt;
                font-weight: bold;
              }
              .ticket-check {
                input[type=checkbox] {
                  &:checked {
                    background: $theme-primary-color;
                  }
              }
            }
              .quantity {
                width: 50%;
                text-align: center;
              }
              .select-ticket {
                border-style: hidden;
                font-size: 12pt;
                td {
                  padding-bottom: 6pt;
                }
              }
              .total-price {
                color: #666666;
                font-size: 16pt;
                font-weight: bold;
                border-bottom: hidden;
              }
            }
            .information-user {
              color: #B0941E;
              font-size: 13pt;
              font-weight: bold;
              margin: 5pt 0;
            }
            .input-user {
              margin-left: 15pt;
              width: 90%;
            }
            .payment {
              margin: 20pt 0;
              width: 90%;
              text-align: center;
              .payment-submit {
                border: 0pt;
                border-radius: 30pt;
                background-color: #D5BE65;
                color: #FFFFFF;
                font-size: 12pt;
                width: 180pt;
                height: 26pt;
              }
            }
            .ticket-page-action-box-radio {
              margin-right: 10pt;
              margin-bottom: 10pt;
            }
          }
          .ticket-page-action-slide  {
            width: 40%;
            height: 430pt;
            @media (max-width: 664.98px) {
              width: 100%;
              margin: auto;
            }
            .carousel-wrapper {
              position: relative;
              display: block;
              height: 100%;
              #topCarousel {
                height: 100%;
                .carousel-inner {
                  height: 100%;
                  .carousel-item {
                    margin-left: 10pt;
                    width: 100%;
                    height: 100%;
                    aspect-ratio: 791.9 / 351.1;
                    overflow: hidden;
                    img {
                      width: 100%;
                    }
                    .active {
                      height: 50pt;
                    }
                  }
                }
                .slider-controller {
                  display: flex;
                  justify-content: center;
                  .nav-button {
                    width: 58pt;
                    height: 41.54pt;
                    border: solid 1px #D5BE65;
                    font-size: 29pt;
                    color: #D5BE65;
                    display: flex;
                    justify-content: center;
                    cursor: pointer;
                    &.next {
                      margin: 0 20pt;
                    }
                    &:hover {
                      color: #fff;
                      background: #D5BE65;
                      border: solid 1px #fff;
                    }
                  }
                }
                .carousel-control-prev, .carousel-control-next {
                  padding-bottom: 36.9pt;
                }
              }
              .calendar {
                padding: 5pt;
                font-size: 12px;
                position: absolute;
                right: 13pt;
                bottom: 60pt;
                background: #fff;
                z-index: 1;
                width: 200pt;

                @media (max-width: 1199.98px) {
                  width: 30%;
                }
                @media (max-width: 991.98px) {
                  width: 100%;
                  position: relative;
                  right: 0;
                  bottom: 0;
                  padding: 0;
                  margin-top: 10px;
                }
                .fc-header-toolbar {
                  background: $theme-primary-color;;
                  margin: 0;
                  color: #fff;
                  height: 50px;
                }
                .fc-daygrid-day-top {
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                }
                .fc-prev-button, .fc-next-button, .fc-gridButton-button, .fc-spacer-button {
                  background: none;
                  border: none;
                }
                .fc-daygrid-day-events {
                  display: none;
                }
                .fc-daygrid-day-top {
                  justify-content: center;
                  .fc-daygrid-day-number {
                    width: 26px;
                    height: 26px;
                    display: inline-flex;
                    aspect-ratio: 1;
                    align-items: center;
                    justify-content: center;
                    margin: 2px;
                    &.highlight {
                      background: $theme-primary-hover-color;;
                      border-radius: 50%;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .conductor-page {
        max-width: $theme-max-width;
        width: 100%;
        margin: 0 auto;
        font-family: TimesNewRoman,Times New Roman,Times,Baskerville,Georgia,serif;
        .h-line-smaller {
          height: 0.4pt;
          background-color: #A48037;
          margin: 20pt 0;
        }
        .conductor-page-banner {
          max-width: $theme-max-width;
          width: 100%;
          margin: 0;
          .conductor-page-banner-text {
            margin-left: 16pt;
            .conductor-page-banner-text-title {
              .conductor-page-banner-text-title-name {
                font-size: 17pt;
              }
              .conductor-page-banner-text-title-conductor {
                font-size: 16pt;
              }
            }
            .conductor-page-banner-text-description {
              font-size: 12pt;
            }
          }
          .conductor-page-banner-img {
            width: 195.22pt;
            height: 230pt;
            @include media-breakpoint-down(sm) {
              justify-content: center;
            }
            .conductor-img {
              @include media-breakpoint-down(xl) {
                width: 100%;
                height: 100%;
              }
              width: 195.22pt;
              height: 230pt;
              object-fit: cover;
            }
          }
        }
        .conductor-page-body-description {
          font-size: 11pt;
        }
        .conductor-page-text-link {
          font-size: 13pt;
          @include media-breakpoint-up(xl) {
            display: flex;
          }
          .conductor-page-link-item {
            @include media-breakpoint-up(xl) {
              width: 50%;
            }
          }
          a {
            color: $theme-primary-color;
          }
        }
      }
      .student-ticket-page {
        max-width: $theme-max-width;
        width: 100%;
        margin: 0 auto;
        font-family: Verdana,Geneva,sans-serif;
        .student-ticket-page-title {
          font-size: 15pt;
          font-weight: bold;
        }
        .student-ticket-page-head {
          margin-top: 20pt;
          @media (max-width: 630px) {
            flex-direction: column;
          }
          .student-ticket-page-head-img {
            @media (max-width: 630px) {
              text-align: center;
            }
            img {
              width: 170.7pt;
              height: 120pt;
              border-radius: 12pt;
              @media (max-width: 630px) {
                width: 90%;
                height: 90%;
              }
            }
          }
          .student-ticket-page-head-text {
            .student-ticket-page-head-text-title {
              font-weight: bold;
              font-size: 15pt;
              .title-ticket {
                margin-right: 60pt;
              }
            }
            .student-ticket-page-head-text-description {
              font-size: 11pt;
            }
          }
        }
        .student-ticket-page-body {
          .student-ticket-page-body-contact {
            .student-ticket-page-body-contact-label {
              font-size: 14pt;
              font-weight: bold;
            }
            .student-ticket-page-body-contact-info {
              font-size: 11pt;
            }
          }
        }
      }
      .membership-patron-page {
        max-width: $theme-max-width;
        width: 100%;
        margin: 0 auto;
        font-family: Verdana,Geneva,sans-serif;
        .membership-patron-page-head {
          .membership-patron-page-head-title {
            font-size: 16pt;
            font-weight: bold;
          }
          .membership-patron-page-head-text {
            font-size: 11pt;
          }
        }
        .membership-patron-page-body {
          .membership-patron-page-body-membership {
            .membership-patron-page-body-membership-img {
              .membership-img {
                width: 158.6pt;
                height: 130pt;
                border-radius: 12pt;
                object-fit: cover;
              }
            }
            .membership-patron-page-body-membership-title {
              font-size: 15pt;
              font-weight: bold;
            }
            .membership-patron-page-body-membership-text {
              font-size: 11pt;
            }
            .membership-patron-page-body-membership-btn {
              .btn-more-info {
                width: 74pt;
                height: 26pt;
                background: #fff;
                color: $theme-primary-color;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10pt;
                border-radius: 15pt;
                border: 1px solid $theme-primary-color;
                span {
                  font-size: 9pt;
                }
                &:hover {
                  background: $theme-primary-hover-color;
                }
              }
            }
          }
          .membership-patron-page-body-patron {
            .membership-patron-page-body-patron-img {
              .patron-img {
                width: 158.6pt;
                height: 130pt;
                border-radius: 12pt;
                object-fit: cover;
              }
            }
            .membership-patron-page-body-patron-title {
              font-size: 15pt;
              font-weight: bold;
            }
            .membership-patron-page-body-patron-text {
              font-size: 11pt;
            }
            .membership-patron-page-body-patron-btn {
              .btn-more-info {
                width: 74pt;
                height: 26pt;
                background: #fff;
                color: $theme-primary-color;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10pt;
                border-radius: 15pt;
                border: 1px solid $theme-primary-color;
                span {
                  font-size: 9pt;
                }
                &:hover {
                  background: $theme-primary-hover-color;
                }
              }
            }
          }
        }
      }

      .membership-detail-page {
        max-width: $theme-max-width;
        width: 100%;
        margin: 0 auto;
        font-family: Verdana, Geneva, sans-serif;
        .membership-detail-page-head {
          display: flex;
          align-items: center;
          @include media-breakpoint-down(md) {
            direction: revert;
            flex-direction: column;
          }
          .membership-detail-page-head-img {
            .membership-img {
              width: 158.6pt;
              height: 130pt;
              border-radius: 12pt;
            }
          }
          .membership-detail-page-head-text {
            @include media-breakpoint-down(md) {
              margin-top: 20pt;
              margin-left: 0;
            }
            margin-left: 60pt;
            .membership-detail-page-head-text-title {
              font-size: 15pt;
              font-weight: bold;
            }
            .membership-detail-page-head-text-des {
              font-size: 11pt;
            }
          }
        }
        .membership-detail-page-body {
          margin-top: 50pt;
          .membership-detail-page-body-ticket-gold {
            .membership-detail-page-body-gold {
              @media (max-width: 576px) {
                flex-direction: column;
              }
              .membership-detail-page-body-gold-block {
                margin: auto 0;
                .membership-detail-page-body-gold-block-price-and-info {
                  font-size: 11pt;
                }
                .membership-detail-page-body-gold-btn-moreinfo {
                  .collapse-toggle {
                    border: none;
                    background-color: white;
                  }
                }
              }
              .membership-detail-page-body-gold-btn {
                display: flex;
                justify-content: center;
                @media (max-width: 576px) {
                  display: none;
                }
                font-size: 11pt;

                .btn-join-now {
                  width: 101.5pt;
                  height: 35pt;
                  background: $theme-primary-color;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 15pt;

                  .ico-ticket {
                    width: 20pt;
                    height: 20pt;
                    margin-right: 5pt;
                  }

                  span {
                    font-size: 11pt;
                  }

                  &:hover {
                    background: $theme-primary-hover-color;
                  }
                }
              }
            }
            .membership-detail-page-body-gold-btn-small {
              margin-top: 5pt;
              @media (min-width: 576px) {
                display: none;
              }
              font-size: 11pt;
              .btn-join-now {
                width: 101.5pt;
                height: 35pt;
                background: $theme-primary-color;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15pt;
                .ico-ticket {
                  width: 20pt;
                  height: 20pt;
                  margin-right: 5pt;
                }
                span {
                  font-size: 11pt;
                }
                &:hover {
                  background: $theme-primary-hover-color;
                }
              }
            }
            .membership-detail-page-body-gold-moreinfo {
              max-width: 255pt;
            }
          }
          .membership-detail-page-body-ticket-silver {
            .membership-detail-page-body-silver {
              @media (max-width: 576px) {
                flex-direction: column;
              }
              .membership-detail-page-body-silver-block {
                margin: auto 0;
                .membership-detail-page-body-silver-block-price-and-info {
                  font-size: 11pt;
                }
                .membership-detail-page-body-silver-btn-moreinfo {
                  .collapse-toggle {
                    border: none;
                    background-color: white;
                  }
                }
              }
              .membership-detail-page-body-silver-btn {
                display: flex;
                justify-content: center;
                @media (max-width: 576px) {
                  display: none;
                }
                font-size: 11pt;

                .btn-join-now {
                  width: 101.5pt;
                  height: 35pt;
                  background: $theme-primary-color;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 15pt;

                  .ico-ticket {
                    width: 20pt;
                    height: 20pt;
                    margin-right: 5pt;
                  }

                  span {
                    font-size: 11pt;
                  }

                  &:hover {
                    background: $theme-primary-hover-color;
                  }
                }
              }
            }
            .membership-detail-page-body-silver-btn-small {
              margin-top: 5pt;
              @media (min-width: 576px) {
                display: none;
              }
              font-size: 11pt;
              .btn-join-now {
                width: 101.5pt;
                height: 35pt;
                background: $theme-primary-color;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15pt;
                .ico-ticket {
                  width: 20pt;
                  height: 20pt;
                  margin-right: 5pt;
                }
                span {
                  font-size: 11pt;
                }
                &:hover {
                  background: $theme-primary-hover-color;
                }
              }
            }
            .membership-detail-page-body-silver-moreinfo {
              max-width: 255pt;
            }
          }
          .membership-detail-page-body-contact {
            .membership-detail-page-body-contact-title {
              font-size: 14pt;
              font-weight: bold;
            }
            .membership-detail-page-body-contact-info {
              font-size: 11pt;
            }
          }
        }
      }
      .patron-detail-page {
        max-width: $theme-max-width;
        width: 100%;
        margin: 0 auto;
        font-family: Verdana, Geneva, sans-serif;
        .patron-detail-page-head {
          display: flex;
          align-items: center;
          @include media-breakpoint-down(md) {
            direction: revert;
            flex-direction: column;
          }
          .patron-detail-page-head-img {
            .membership-img {
              width: 158.6pt;
              height: 130pt;
              border-radius: 12pt;
            }
          }
          .patron-detail-page-head-text {
            @include media-breakpoint-down(md) {
              margin-top: 20pt;
              margin-left: 0;
            }
            margin-left: 60pt;
            .patron-detail-page-head-text-title {
              font-size: 15pt;
              font-weight: bold;
            }
            .patron-detail-page-head-text-des {
              font-size: 11pt;
            }
          }
        }
        .patron-detail-page-body {
          margin-top: 50pt;
          .patron-detail-page-body-ticket-diamond {
            .patron-detail-page-body-diamond {
              @media (max-width: 576px) {
                flex-direction: column;
              }
              .patron-detail-page-body-diamond-block {
                margin: auto 0;
                .patron-detail-page-body-diamond-block-price-and-info {
                  font-size: 11pt;
                }
                .patron-detail-page-body-diamond-btn-moreinfo {
                  .collapse-toggle {
                    border: none;
                    background-color: white;
                  }
                }
              }
              .patron-detail-page-body-diamond-btn {
                display: flex;
                justify-content: center;
                @media (max-width: 576px) {
                  display: none;
                }
                font-size: 11pt;

                .btn-join-now {
                  width: 101.5pt;
                  height: 35pt;
                  background: $theme-primary-color;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 15pt;

                  .ico-ticket {
                    width: 20pt;
                    height: 20pt;
                    margin-right: 5pt;
                  }

                  span {
                    font-size: 11pt;
                  }

                  &:hover {
                    background: $theme-primary-hover-color;
                  }
                }
              }
            }
            .patron-detail-page-body-diamond-btn-small {
              margin-top: 5pt;
              @media (min-width: 576px) {
                display: none;
              }
              font-size: 11pt;
              .btn-join-now {
                width: 101.5pt;
                height: 35pt;
                background: $theme-primary-color;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15pt;
                .ico-ticket {
                  width: 20pt;
                  height: 20pt;
                  margin-right: 5pt;
                }
                span {
                  font-size: 11pt;
                }
                &:hover {
                  background: $theme-primary-hover-color;
                }
              }
            }
            .patron-detail-page-body-diamond-moreinfo {
              max-width: 255pt;
            }
          }
          .patron-detail-page-body-ticket-gold {
            .patron-detail-page-body-gold {
              @media (max-width: 576px) {
                flex-direction: column;
              }
              .patron-detail-page-body-gold-block {
                margin: auto 0;
                .patron-detail-page-body-gold-block-price-and-info {
                  font-size: 11pt;
                }
                .patron-detail-page-body-gold-btn-moreinfo {
                  .collapse-toggle {
                    border: none;
                    background-color: white;
                  }
                }
              }
              .patron-detail-page-body-gold-btn {
                display: flex;
                justify-content: center;
                @media (max-width: 576px) {
                  display: none;
                }
                font-size: 11pt;

                .btn-join-now {
                  width: 101.5pt;
                  height: 35pt;
                  background: $theme-primary-color;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 15pt;

                  .ico-ticket {
                    width: 20pt;
                    height: 20pt;
                    margin-right: 5pt;
                  }

                  span {
                    font-size: 11pt;
                  }

                  &:hover {
                    background: $theme-primary-hover-color;
                  }
                }
              }
            }
            .patron-detail-page-body-gold-btn-small {
              margin-top: 5pt;
              @media (min-width: 576px) {
                display: none;
              }
              font-size: 11pt;
              .btn-join-now {
                width: 101.5pt;
                height: 35pt;
                background: $theme-primary-color;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15pt;
                .ico-ticket {
                  width: 20pt;
                  height: 20pt;
                  margin-right: 5pt;
                }
                span {
                  font-size: 11pt;
                }
                &:hover {
                  background: $theme-primary-hover-color;
                }
              }
            }
            .patron-detail-page-body-gold-moreinfo {
              max-width: 255pt;
            }
          }
          .patron-detail-page-body-ticket-silver {
            .patron-detail-page-body-silver {
              @media (max-width: 576px) {
                flex-direction: column;
              }
              .patron-detail-page-body-silver-block {
                margin: auto 0;
                .patron-detail-page-body-silver-block-price-and-info {
                  font-size: 11pt;
                }
                .patron-detail-page-body-silver-btn-moreinfo {
                  .collapse-toggle {
                    border: none;
                    background-color: white;
                  }
                }
              }
              .patron-detail-page-body-silver-btn {
                display: flex;
                justify-content: center;
                @media (max-width: 576px) {
                  display: none;
                }
                font-size: 11pt;

                .btn-join-now {
                  width: 101.5pt;
                  height: 35pt;
                  background: $theme-primary-color;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 15pt;

                  .ico-ticket {
                    width: 20pt;
                    height: 20pt;
                    margin-right: 5pt;
                  }

                  span {
                    font-size: 11pt;
                  }

                  &:hover {
                    background: $theme-primary-hover-color;
                  }
                }
              }
            }
            .patron-detail-page-body-silver-btn-small {
              margin-top: 5pt;
              @media (min-width: 576px) {
                display: none;
              }
              font-size: 11pt;
              .btn-join-now {
                width: 101.5pt;
                height: 35pt;
                background: $theme-primary-color;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15pt;
                .ico-ticket {
                  width: 20pt;
                  height: 20pt;
                  margin-right: 5pt;
                }
                span {
                  font-size: 11pt;
                }
                &:hover {
                  background: $theme-primary-hover-color;
                }
              }
            }
            .patron-detail-page-body-silver-moreinfo {
              max-width: 255pt;
            }
          }
          .patron-detail-page-body-contact {
            .patron-detail-page-body-contact-title {
              font-size: 14pt;
              font-weight: bold;
            }
            .patron-detail-page-body-contact-info {
              font-size: 11pt;
            }
          }
        }
      }
      .sponsor-detail-page {
        .sponsor-page-text-link {
          font-size: 13pt;
          font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
          @include media-breakpoint-up(xl) {
            display: flex;
          }
          .sponsor-page-link-item {
            @include media-breakpoint-up(xl) {
              width: 50%;
            }
          }
          a {
            color: $theme-primary-color;
          }
        }
      }
      .member-page {
        &__content {
          font-family: TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif;
          font-size: 12pt;
          display: flex;
          flex-direction: column;
        }
        &__info {
          display: flex;
          justify-content: center;
          margin-bottom: 20pt;
          @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: center;
          }
          &-img {
            max-width: 160pt;
            max-height: 240pt;
            margin-right: 20pt;
            object-fit: contain;
            @include media-breakpoint-down(sm) {
              max-width: 120pt;
              max-height: 200pt;
              margin-right: 0;
            }
          }
          &-detail {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            min-width: 210pt;
            > div:first-child {
              font-weight: bold;
            }
            @include media-breakpoint-down(sm) {
              align-items: center;
            }
          }
        }
      }
      .page-news {
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        background-color: #f8f9fa;
        .article-card {
          background: #fff;
          border-radius: 12px;
          overflow: hidden;
          transition: transform 0.3s ease;
          height: 100%;
          box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
          &:hover {
            transform: translateY(-5px);
            cursor: pointer;
          }
          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
          }
          .card-body {
            padding: 1.5rem;
          }
        }
        .article-date {
          color: var(--secondary-color);
          font-size: 10pt;
          margin-bottom: 0.5rem;
        }
        .article-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          font-size: 14pt;
          font-weight: 600;
          margin-bottom: 1rem;
          color: #333;
          line-height: 1.4;
        }
        .share-button {
          color: var(--secondary-color);
          border: none;
          background: none;
          padding: 0;
        }

        .search-box {
          border-radius: 12px;
          padding: 1rem;
          background: #fff;
          box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
        }

        .search-input {
          border-radius: 20px;
          border: 1px solid #ddd;
          padding: 0.5rem 1rem;
        }

        .older-articles {
          border-radius: 12px;
          padding: 1rem;
          background: #fff;
          box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);

          &__item {
            color: #333;
            display: flex;
            align-items: center;
            padding: 0.75rem 0;
            text-decoration: none;
            transition: color 0.3s ease;
            &:not(:last-child) {
              border-bottom: 2px solid rgba(0, 0, 0, 0.1);
            }
            &:hover {
              color: var(--bs-blue);
            }
          }
        }
        .sidebar-title {
          text-align: center;
          font-weight: 600;
          color: #a48037;
          margin-bottom: 1rem;
          padding-bottom: 0.5rem;
          border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

#user-login {
  .gradient-custom-2 {
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
  }
  .btn-block {
    width: 50%;
  }
}

.table.table-bordered {
  border:none;
  tr {
    border: none;
    td {
      border: none;
    }
  }
}
.header, .header-toppage {
  .navbar {
    .container-fluid {
      .menu-wrapper {
        @media(max-width: 360px){ 
          width: 50%;
          display: block;
        }
      }
    }
  }
}
.font-italic {
  font-style: italic;
}
.sponsor {
  &__footer {
    display: flex;
    justify-content: space-between;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    a {
      text-decoration: none;
      color: #a48037;
      font-family: Helvetica, Times, Baskerville, Georgia, serif;
      font-size: 12pt;
      @include media-breakpoint-down(md) {
        display: flex;
        justify-content: center;
      }
    }
  }
}
// Body
$body-bg: #f8fafc;
$theme-primary-color: #A48037;
$theme-primary-hover-color: #e2d193; // 30% brighter than primary color
$theme-max-width: 791pt;
$theme-content-max-width: 70%;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
